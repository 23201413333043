import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import { navigate } from "@reach/router"
import { Carousel, Button } from "react-bootstrap"

import "./EventTemplate.scss"

import LocationIcon from "../images/locationIcon.svg"
import CalendarIcon from "../images/calendarIcon.svg"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title + " " + frontmatter.year}
        description={frontmatter.description}
      />

      <div className="blog-post-container">
        <div className="blog-post">
          <h1 className="heading">{frontmatter.title}</h1>

          <div className="meta">
            <p className="date">
              <span>
                <CalendarIcon className="calendarIcon" />
                {frontmatter.date}
              </span>
              <span>
                {frontmatter.time != null &&
                  frontmatter.time.length > 0 &&
                  " - " + frontmatter.time + " Uhr"}
              </span>
            </p>

            <p className="location">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={frontmatter.locationLink}
              >
                <LocationIcon className="locationIcon" />
                {frontmatter.locationShort}
              </a>
            </p>
          </div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <Link to="/" onClick={back}>
            <Button variant="danger">Zurück</Button>
          </Link>

          {frontmatter.images.length > 0 && (
            <h3 className="imagesHeader">Bilder vom Auftritt:</h3>
          )}
        </div>
        {frontmatter.images.length > 0 && (
          <div className="images">
            <Carousel
              indicators={false}
              nextIcon={
                <span
                  aria-hidden="true"
                  className="carousel-control-next-icon"
                />
              }
              prevIcon={
                <span
                  aria-hidden="true"
                  className="carousel-control-prev-icon"
                />
              }
            >
              {frontmatter.images.map(image => {
                return (
                  <Carousel.Item key={image.id}>
                    <Image
                      filename={image.relativePath}
                      alt="Bild vom Auftritt"
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>
        )}
      </div>
    </Layout>
  )
}

function back(event) {
  event.preventDefault()
  if (window.history.length > 1) {
    window.history.go(-1)
  } else {
    navigate("/#top")
  }
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "dd DD.MM.YY", locale: "de-DE")
        year: date(formatString: "YYYY", locale: "de-DE")
        path
        title
        description
        time
        locationShort
        locationLink
        titleImage {
          relativePath
        }
        images {
          id
          relativePath
        }
      }
    }
  }
`
